import { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useIsMounted } from 'usehooks-ts';
import { isMobile } from 'react-device-detect';
import { API } from 'aws-amplify';
import publicIp from 'public-ip';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';

import * as Utils from '../Utils/Utils';

const GeoAutoComplete = ({ onLocation }) => {
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [location, setLocation] = useState(null);
	const [reverseAddress, setReverseAddress] = useState(null);
	const [locationManual, setLocationManual] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const isMounted = useIsMounted();

	useEffect(() => {
		enqueueSnackbar(`Locating...`, Utils.snackbar('info'));
		var locationOptions = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		};

		const locationSuccess = (pos) => {
			if (isMounted()) {
				setLocation(pos.coords);
				enqueueSnackbar(`Location acquired - check it out`, Utils.snackbar('success'));
			}
		};

		const locationError = (error) => {
			enqueueSnackbar(`Location error (${error.message}) - trying another way`, Utils.snackbar('warning'));
			publicIp.v4().then((ip) => {
				API.get('gragramapi', `/api/locate?ip=${ip}`)
					.then((response) => {
						if (isMounted()) {
							setLocation(response.data.location);
							enqueueSnackbar(`Location acquired - check it out`, Utils.snackbar('success'));
						}
					})
					.catch((error) => {
						enqueueSnackbar(
							`Location error (${error.message}), please type in your address`,
							Utils.snackbar('error')
						);
					});
			});
		};

		navigator.geolocation.getCurrentPosition(locationSuccess, locationError, locationOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!_.isNil(location)) {
			const { latitude = null, longitude = null } = location;
			API.get('gragramapi', `/api/georeverse?lat=${latitude}&long=${longitude}`)
				.then((response) => {
					if (isMounted()) {
						setReverseAddress(_.get(response.data, ['results', 0, 'formatted']));
					}
				})
				.catch((error) => {
					console.error(error);
				});

			if (_.isFunction(onLocation)) {
				onLocation(location);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, onLocation]);

	const fetch = useMemo(
		() =>
			_.debounce((address, callback) => {
				const { latitude = null, longitude = null } = location || {};
				API.get(
					'gragramapi',
					`/api/autocomplete?lat=${latitude}&long=${longitude}&address=${encodeURIComponent(address)}`
				)
					.then((response) => {
						callback(_.get(response.data, ['results']));
					})
					.catch((error) => {
						console.error(error);
					});
			}, 250),
		[location]
	);

	useEffect(() => {
		let active = true;

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch(inputValue, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	const onChange = (event, newValue) => {
		setOptions(newValue ? [newValue, ...options] : options);
		setValue(newValue);
		if (_.isFunction(onLocation)) {
			onLocation({
				latitude: _.get(newValue, ['lat']),
				longitude: _.get(newValue, ['lon'])
			});
		}
	};

	const onInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	const switchLocationType = () => {
		setLocationManual((old) => !old);
	};

	return (
		<Box sx={{ mt: '1em', mb: '1em' }}>
			{locationManual ? (
				<Autocomplete
					getOptionLabel={(option) => (typeof option === 'string' ? option : option.formatted)}
					filterOptions={(x) => x}
					options={options}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={value}
					onChange={onChange}
					onInputChange={onInputChange}
					renderInput={(params) => <TextField autoFocus {...params} label="Add a location" fullWidth />}
					renderOption={(props, option) => {
						return (
							<li {...props}>
								<Grid container>
									<Grid item>
										<Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
									</Grid>
									<Grid item xs>
										{option.address_line1}
										<Typography variant="body2" color="text.secondary">
											{option.address_line2}
										</Typography>
									</Grid>
								</Grid>
							</li>
						);
					}}
				/>
			) : (
				<div>
					<Typography variant="body2">
						<MyLocationIcon color="primary" sx={{ mr: '0.25em', verticalAlign: 'middle' }} />
						{_.isNil(reverseAddress) ? (
							<>
								{'Acquiring location in progress...'}
								<CircularProgress size={'1em'} sx={{ ml: '0.5em' }} />
							</>
						) : (
							`Your location: ${reverseAddress}`
						)}
					</Typography>
					<Typography component="div" variant="caption">
						{isMobile
							? 'Make sure to turn on your location for high accuracy or type in your address'
							: 'Location on a computer is usually inaccurate, enter your address instead'}
					</Typography>
					{!_.isNil(reverseAddress) && (
						<Button variant="outlined" onClick={switchLocationType} sx={{ margin: '1em' }}>
							{'Location not good enough?'}
						</Button>
					)}
				</div>
			)}
		</Box>
	);
};

export default GeoAutoComplete;
