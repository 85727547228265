import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	color: theme.palette.primary.main,
	backgroundColor: '#ebd872',
	flexDirection: 'row-reverse',
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	borderLeft: '1px solid rgba(0, 0, 0, .125)',
	borderRight: '1px solid rgba(0, 0, 0, .125)',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
		marginRight: theme.spacing(0.5)
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

export default AccordionSummary;
