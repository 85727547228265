import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './index.css';
import App from './App';

const theme = createTheme({
	typography: {
		fontFamily: `"Courier Prime", "Roboto", "Helvetica"`
	},
	palette: {
		primary: {
			main: '#d7300e'
		},
		background: {
			paper: '#ffea7c'
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3} autoHideDuration={3000}>
				<App />
			</SnackbarProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
