import Collapse from '@mui/material/Collapse';
import _ from 'lodash';

export const snackbar = (variant) => {
	return {
		variant,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left'
		},
		TransitionComponent: Collapse
	};
};

export const formatInfo = (info, key, def = '-') => {
	return _.has(info, key) ? _.get(info, key) : def;
};
