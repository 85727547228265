import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	borderLeft: '1px solid rgba(0, 0, 0, .125)',
	borderRight: '1px solid rgba(0, 0, 0, .125)',
	textAlign: 'left',
	color: theme.palette.primary.main,
	backgroundColor: '#ffea7c'
}));

export default AccordionDetails;
