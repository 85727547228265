export const ISOLINES = [5, 10, 20];

export const INFO = {
	NAME: ['name'],
	ADDRESS: ['location', 'formatted_address'],
	DISTANCE: ['distance'],
	LATITUDE: ['geocodes', 'main', 'latitude'],
	LONGITUDE: ['geocodes', 'main', 'longitude'],
	CATEGORIES: ['categories'],
	PHONE: ['tel'],
	HOURS: ['hours', 'display'],
	OPEN_NOW: ['hours', 'open_now'],
	MENU: ['menu'],
	TAGS: ['tastes'],
	PRICE: ['price'],
	RATING: ['rating'],
	N_RATINGS: ['stats', 'total_ratings'],
	BOOKING: ['features', 'services', 'dine_in', 'reservations'],
	WEBSITE: ['website'],
	FACEBOOK: ['social_media', 'facebook_id'],
	INSTAGRAM: ['social_media', 'instagram'],
	ACCESSIBILITY: ['features', 'amenities', 'wheelchair_accessible'],
	OUTDOOR: ['features', 'amenities', 'outdoor_seating'],
	TAKEAWAY: ['properties', 'facilities', 'takeout'],
	DELIVERY: ['features', 'services', 'delivery']
};

export const LOCAL_STORAGE = 'gragram_places';
export const LS_FAVS = 'favs';
export const LS_BLACKLIST = 'blacklist';
export const LS_DEFAULT = '{"version": 0.1}';
export const LS_DEFAULT_CATEGORIES = 'gragram_default_categories';
export const PLACE_ID_KEY = 'fsq_id';

export const SEARCH_TYPE = {
	RANDOM: 'RANDOM',
	FROM_FAVS: 'FROM_FAVS'
};
