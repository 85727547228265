import { useState, useRef, useMemo, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useIsMounted } from 'usehooks-ts';
import AccessibleIcon from '@mui/icons-material/Accessible';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import DeckIcon from '@mui/icons-material/Deck';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import EuroIcon from '@mui/icons-material/Euro';
import FacebookIcon from '@mui/icons-material/Facebook';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import GoogleIcon from '@mui/icons-material/Google';
import HikingIcon from '@mui/icons-material/Hiking';
import InstagramIcon from '@mui/icons-material/Instagram';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Accordion from '@mui/material/Accordion';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import '@fontsource/courier-prime';
import * as turf from '@turf/turf';
import _ from 'lodash';

import * as Utils from './Utils/Utils';
import * as c from './Utils/Constants';
import GeoAutoComplete from './Components/GeoAutoComplete';
import AccordionSummary from './Components/AccordionSummary';
import AccordionDetails from './Components/AccordionDetails';
import PlaceList from './Components/PlaceList';

import './App.css';
import 'maplibre-gl/dist/maplibre-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';

import { Amplify, API } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

maplibregl.workerClass = maplibreglWorker;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function App() {
	const [inProgress, setInProgress] = useState(null);
	const [result, setResult] = useState(null);
	const [resultList, setResultList] = useState(null);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [walkingTime, setWalkingTime] = useState(null);
	const [categories, setCategories] = useState(null);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [actionMenuVisible, setActionMenuVisible] = useState(false);
	const [userPlaces, setUserPlaces] = useState(null);
	const [modalPlaces, setModalPlaces] = useState(null);
	const [lastSearchType, setLastSearchType] = useState(null);
	const [helpVisible, setHelpVisible] = useState(false);

	const mapContainer = useRef(null);
	const map = useRef(null);
	const mapIsLoaded = useRef(false);
	const locationMarker = useRef(null);
	const resultMarker = useRef(null);
	const sampleIds = useRef({});
	const actionMenuAnchorRef = useRef(null);
	const prevActionMenuVisible = useRef(actionMenuVisible);

	const { enqueueSnackbar } = useSnackbar();
	const isMounted = useIsMounted();

	useEffect(() => {
		if (_.isNil(map.current)) {
			map.current = new maplibregl.Map({
				container: mapContainer.current,
				style: `https://api.maptiler.com/maps/streets/style.json?key=${
					process.env.REACT_APP_MAP_TILER_API_KEY
				}`,
				center: [2.3726649427923023, 48.85843733666168],
				zoom: 14
			});

			map.current.addControl(new maplibregl.NavigationControl(), 'top-right');

			map.current.on('load', () => {
				mapIsLoaded.current = true;
				setWalkingTime(10);
			});
		}
	}, []);

	useEffect(() => {
		API.get('gragramapi', `/api/categories`)
			.then((response) => {
				if (isMounted()) {
					setCategories(response.data);
					let defaultCategories = null;
					try {
						defaultCategories = JSON.parse(localStorage.getItem(c.LS_DEFAULT_CATEGORIES) || '[]');
					} catch (e) {
						console.error(e);
					}

					if (_.isNil(defaultCategories) || _.isEmpty(defaultCategories)) {
						setSelectedCategories(_.sampleSize(response.data, 3));
					} else {
						setSelectedCategories(defaultCategories);
					}
				}
			})
			.catch((error) => {
				enqueueSnackbar(`Error: ${error.message}`, Utils.snackbar('error'));
				setCategories([]);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUserPlaces(JSON.parse(localStorage.getItem(c.LOCAL_STORAGE) || c.LS_DEFAULT));
	}, []);

	const currentPlaceId = useMemo(() => {
		return _.get(result, [c.PLACE_ID_KEY]);
	}, [result]);

	useEffect(() => {
		const { latitude = null, longitude = null } = currentLocation || {};
		if (_.isNil(latitude) || _.isNil(longitude)) {
			return;
		}

		if (_.isNil(locationMarker.current)) {
			const locationIcon = document.createElement('div');
			locationIcon.classList.add('location-marker');
			locationMarker.current = new maplibregl.Marker(locationIcon)
				.setLngLat([longitude, latitude])
				.addTo(map.current);
		} else {
			locationMarker.current.setLngLat([longitude, latitude]);
		}
		map.current.flyTo({ center: [longitude, latitude], zoom: 14 });
	}, [currentLocation]);

	const routeSample = (res, searchType) => {
		const { latitude = null, longitude = null } = currentLocation || {};

		if (_.isNil(res) || _.isNil(latitude) || _.isNil(longitude)) {
			setInProgress(null);
		} else {
			setInProgress('routing...');
			setResult(res);

			const currentId = _.get(sampleIds.current, [searchType, walkingTime], 0);
			_.set(sampleIds.current, [searchType, walkingTime], currentId + 1);

			const markerLongitude = _.get(res, c.INFO.LONGITUDE);
			const markerLatitude = _.get(res, c.INFO.LATITUDE);

			if (_.isNil(resultMarker.current)) {
				const restaurantIcon = document.createElement('div');
				restaurantIcon.classList.add('restaurant-marker');
				resultMarker.current = new maplibregl.Marker(restaurantIcon)
					.setLngLat([markerLongitude, markerLatitude])
					.addTo(map.current);
			} else {
				resultMarker.current.setLngLat([markerLongitude, markerLatitude]);
			}

			const route = {
				start: currentLocation,
				end: { longitude: markerLongitude, latitude: markerLatitude }
			};

			if (map.current.getSource('route')) {
				map.current.removeLayer('route');
				map.current.removeSource('route');
			}

			API.get(
				'gragramapi',
				`/api/routing?startLat=${route.start.latitude}&startLong=${route.start.longitude}&endLat=${
					route.end.latitude
				}&endLong=${route.end.longitude}`
			)
				.then((response) => {
					if (isMounted()) {
						map.current.addSource('route', {
							type: 'geojson',
							data: _.get(response, ['data', 'features', 0])
						});
						map.current.addLayer({
							id: 'route',
							type: 'line',
							source: 'route',
							layout: {
								'line-join': 'round',
								'line-cap': 'round'
							},
							paint: {
								'line-color': '#d7300e',
								'line-width': 8
							}
						});

						map.current.fitBounds(
							[
								[Math.min(longitude, markerLongitude), Math.min(latitude, markerLatitude)],
								[Math.max(longitude, markerLongitude), Math.max(latitude, markerLatitude)]
							],
							{ padding: { top: 100, bottom: 100, left: 100, right: 100 } }
						);

						setInProgress(null);
					}
				})
				.catch((error) => {
					if (isMounted()) {
						enqueueSnackbar(`Error: ${error.message}`, Utils.snackbar('error'));
						setInProgress(null);
					}
				});
		}
	};

	useEffect(() => {
		const { latitude = null, longitude = null } = currentLocation || {};
		if (_.isNil(latitude) || _.isNil(longitude) || _.isNil(map.current)) {
			return;
		}

		if (mapIsLoaded.current === true) {
			const radius = (0.3 * walkingTime) / 5;
			const options = { steps: 64, units: 'kilometers' };
			const isoline = turf.circle([longitude, latitude], radius, options);
			const bbox = turf.bbox(isoline);

			const isolineName = `isoline`;
			if (map.current.getSource(isolineName)) {
				map.current.removeLayer(isolineName);
				map.current.removeSource(isolineName);
			}

			map.current.addSource(isolineName, {
				type: 'geojson',
				data: isoline
			});

			map.current.addLayer({
				id: isolineName,
				type: 'line',
				source: isolineName,
				layout: {
					'line-join': 'round',
					'line-cap': 'round'
				},
				paint: {
					'line-color': '#d7300e',
					'line-width': 6
				}
			});

			map.current.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]], {
				padding: { top: 100, bottom: 100, left: 100, right: 100 }
			});
		}
	}, [currentLocation, walkingTime]);

	const pickPlaceFromFavs = () => {
		const { latitude = null, longitude = null } = currentLocation || {};
		if (!_.isNil(latitude) && !_.isNil(longitude)) {
			setLastSearchType(c.SEARCH_TYPE.FROM_FAVS);
			setInProgress('searching...');
			setResult(null);

			const samples = _.get(resultList, [c.SEARCH_TYPE.FROM_FAVS, walkingTime]);
			const currentId = _.get(sampleIds.current, [c.SEARCH_TYPE.FROM_FAVS, walkingTime], 0);
			const endListReached = currentId >= _.size(samples);
			if (_.isNil(samples) || endListReached) {
				const results = _.shuffle(
					_.pickBy(
						_.values(_.get(userPlaces, [c.LS_FAVS])),
						(p) =>
							_.isEmpty(
								_.intersection(
									_.flatten(_.map(selectedCategories, (cat) => cat.codes)),
									_.map(_.get(p, c.INFO.CATEGORIES), (c) => c.id)
								)
							) &&
							turf.distance(
								[longitude, latitude],
								[_.get(p, c.INFO.LONGITUDE), _.get(p, c.INFO.LATITUDE)],
								{
									units: 'kilometers'
								}
							) <
								(0.3 * walkingTime) / 5
					)
				);

				if (_.isEmpty(results)) {
					enqueueSnackbar(
						`Unable to find a place (check you're within distance or change your selection criteria)`,
						Utils.snackbar('warning')
					);
					setInProgress(null);
				} else {
					setResultList((list) => {
						let listCopy = _.cloneDeep(list || {});
						_.set(listCopy, [c.SEARCH_TYPE.FROM_FAVS, walkingTime], results);
						return listCopy;
					});
					_.set(sampleIds.current, [c.SEARCH_TYPE.FROM_FAVS, walkingTime], 0);
					routeSample(_.head(results), c.SEARCH_TYPE.FROM_FAVS);
				}
				setInProgress(null);
			} else {
				routeSample(_.get(samples, [currentId]), c.SEARCH_TYPE.FROM_FAVS);
			}
		}
	};

	const searchPlaces = () => {
		const { latitude = null, longitude = null } = currentLocation || {};
		if (!_.isNil(latitude) && !_.isNil(longitude)) {
			setLastSearchType(c.SEARCH_TYPE.RANDOM);
			setInProgress('searching...');
			setResult(null);

			const samples = _.get(resultList, [c.SEARCH_TYPE.RANDOM, walkingTime]);
			const currentId = _.get(sampleIds.current, [c.SEARCH_TYPE.RANDOM, walkingTime], 0);
			const endListReached = currentId >= _.size(samples);
			if (_.isNil(samples) || endListReached) {
				API.get(
					'gragramapi',
					`/api/nearby?lat=${latitude}&long=${longitude}&walkingTime=${walkingTime}&not=${_.join(
						_.map(selectedCategories, (cat) => cat.id),
						','
					)}`
				)
					.then((response) => {
						if (isMounted()) {
							const blacklistIds = _.keys(_.get(userPlaces, [c.LS_BLACKLIST]));
							const results = _.shuffle(
								_.pickBy(
									response.data.results,
									(p) => !_.includes(blacklistIds, _.get(p, [c.PLACE_ID_KEY]))
								)
							);

							if (_.isEmpty(results)) {
								enqueueSnackbar(
									`Unable to find a place (double-check your blacklist or change your selection criteria)`,
									Utils.snackbar('warning')
								);
								setInProgress(null);
							} else {
								setResultList((list) => {
									let listCopy = _.cloneDeep(list || {});
									_.set(listCopy, [c.SEARCH_TYPE.RANDOM, walkingTime], results);
									return listCopy;
								});
								_.set(sampleIds.current, [c.SEARCH_TYPE.RANDOM, walkingTime], 0);
								routeSample(_.head(results), c.SEARCH_TYPE.RANDOM);
							}
						}
					})
					.catch((error) => {
						if (isMounted()) {
							enqueueSnackbar(`Error: ${error.message}`, Utils.snackbar('error'));
							setInProgress(null);
						}
					})
					.finally(() => {
						if (isMounted()) {
							setInProgress(null);
						}
					});
			} else {
				routeSample(_.get(samples, [currentId]), c.SEARCH_TYPE.RANDOM);
			}
		}
	};

	const onLocation = useMemo(() => {
		return (location) => {
			setResultList(null);
			setCurrentLocation(location);
		};
	}, []);

	const handleWalkingTime = (event, newWalkingTime) => {
		if (!_.isNil(newWalkingTime)) {
			setWalkingTime(newWalkingTime);
		}
	};

	const handleCategoryChange = (event, newCategories) => {
		try {
			localStorage.setItem(c.LS_DEFAULT_CATEGORIES, JSON.stringify(newCategories));
		} catch (e) {
			console.error(e);
		}
		setSelectedCategories(newCategories);
		setResultList(null);
	};

	const addToLS = (kind, value) => {
		if (!_.includes([c.LS_FAVS, c.LS_BLACKLIST], kind)) {
			console.error('Invalid kind (favs/blacklist)');
			return false;
		}

		const id = _.get(value, [c.PLACE_ID_KEY]);
		if (_.isNil(id)) {
			console.error('Invalid place id');
			return false;
		}

		try {
			const places = JSON.parse(localStorage.getItem(c.LOCAL_STORAGE) || c.LS_DEFAULT);
			_.set(places, [kind, id], value);
			if (kind === c.LS_BLACKLIST && _.has(places, [c.LS_FAVS, id])) {
				_.set(places, [c.LS_FAVS], _.omit(_.get(places, [c.LS_FAVS]), id));
			}

			localStorage.setItem(c.LOCAL_STORAGE, JSON.stringify(places));
			setUserPlaces(places);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	const addToFavorites = (e) => {
		e.stopPropagation();
		if (addToLS(c.LS_FAVS, result)) {
			enqueueSnackbar(`Places added to your favs`, Utils.snackbar('success'));
		} else {
			enqueueSnackbar(`Error while adding the place to your favs`, Utils.snackbar('error'));
		}
	};

	const blacklist = (e) => {
		e.stopPropagation();
		if (addToLS(c.LS_BLACKLIST, result)) {
			enqueueSnackbar(`Places added to your blacklist`, Utils.snackbar('success'));
			searchPlaces();
		} else {
			enqueueSnackbar(`Error while adding the place to your favs`, Utils.snackbar('error'));
		}
	};

	const expandActionMenu = (e) => {
		e.stopPropagation();
		setActionMenuVisible((prevOpen) => !prevOpen);
	};

	const openMyFavs = (e) => {
		e.stopPropagation();
		setActionMenuVisible(false);
		setModalPlaces(c.LS_FAVS);
	};

	const openMyBlacklist = (e) => {
		e.stopPropagation();
		setActionMenuVisible(false);
		setModalPlaces(c.LS_BLACKLIST);
	};

	const handleCloseActionMenu = (event) => {
		if (actionMenuAnchorRef.current && actionMenuAnchorRef.current.contains(event.target)) {
			return;
		}

		setActionMenuVisible(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setActionMenuVisible(false);
		} else if (event.key === 'Escape') {
			setActionMenuVisible(false);
		}
	};

	useEffect(() => {
		if (prevActionMenuVisible.current === true && actionMenuVisible === false) {
			actionMenuAnchorRef.current.focus();
		}

		prevActionMenuVisible.current = actionMenuVisible;
	}, [actionMenuVisible]);

	const closeModal = () => {
		setModalPlaces(null);
	};

	const onRemovePlace = (placeIds) => {
		try {
			const places = JSON.parse(localStorage.getItem(c.LOCAL_STORAGE) || c.LS_DEFAULT);
			_.set(places, [modalPlaces], _.omit(_.get(places, [modalPlaces]), placeIds));

			localStorage.setItem(c.LOCAL_STORAGE, JSON.stringify(places));
			setUserPlaces(places);
		} catch (e) {
			console.error(e);
		}
	};

	const onClearPlaces = () => {
		try {
			const places = JSON.parse(localStorage.getItem(c.LOCAL_STORAGE) || c.LS_DEFAULT);
			_.set(places, [modalPlaces], {});

			localStorage.setItem(c.LOCAL_STORAGE, JSON.stringify(places));
			setUserPlaces(places);
		} catch (e) {
			console.error(e);
		}
	};

	const showHelp = () => {
		setHelpVisible((old) => !old);
	};

	return (
		<Container align="center">
			<Dialog open={helpVisible} onClose={showHelp} maxWidth="md">
				<DialogTitle sx={{ color: '#d7300e' }}>
					{'About GraGram'}
					<IconButton
						onClick={showHelp}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				<Box sx={{ p: '1em' }}>
					<Box sx={{ mb: '1.5em' }}>
						<Typography variant="h6" sx={{ color: '#d7300e' }}>
							{'My location is not accurate, what can I do?'}
						</Typography>
						<Typography variant="body1">
							{
								'On a computer, location information is very approximate and - when based on IP address - can be quite far from where you actually are. In that case, better use your phone with location turned on, or simply type in your actual address.'
							}
						</Typography>
					</Box>

					<Box sx={{ mb: '1.5em' }}>
						<Typography variant="h6" sx={{ color: '#d7300e' }}>
							{'My favorite and blacklisted places are lost each time I come back, how come?'}
						</Typography>
						<Typography variant="body1">
							{
								"Until we plug in the user account, the data is stored in your browser local storage. Check your browser (Firefox/Chrome) settings to make sure the 'Clear data automatically' is toggled off or add an exception for https://gragram.com"
							}
						</Typography>
					</Box>

					<Box sx={{ mb: '1.5em' }}>
						<Typography variant="h6" sx={{ color: '#d7300e' }}>
							{"Why didn't I get to choose my cookies settings?"}
						</Typography>
						<Typography variant="body1">
							{"We don't use cookies at all! Someone must have eaten them all..."}
						</Typography>
					</Box>

					<Box sx={{ mb: '1.5em' }}>
						<Typography variant="h6" sx={{ color: '#d7300e' }}>
							{'What about my privacy?'}
						</Typography>
						<Typography variant="body1">
							{"Your data stays on your computer and is not sent to anyone. How's that for privacy!"}
						</Typography>
					</Box>
				</Box>
			</Dialog>

			<Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
				<div>
					<div>
						<img
							src="/logo_transparent.png"
							width="64"
							height="64"
							className="App-logo"
							alt="GraGram Logo"
						/>
					</div>
					<Box sx={{ cursor: 'pointer' }} onClick={showHelp}>
						<LiveHelpIcon size="small" fontSize="small" sx={{ verticalAlign: 'middle', mr: '0.15em' }} />
						<Typography variant="caption" sx={{ textDecoration: 'underline' }}>
							{'help'}
						</Typography>
					</Box>
				</div>
				<Box>
					<div>
						<Typography component="span" variant="h5">
							{'GraGram, Miam Miam!'}
						</Typography>
					</div>
					<div>
						<Typography variant="caption">
							{
								"Don't know where to eat and pretty open about it? Eliminate the cuisine styles you don't feel like and try your luck!"
							}
						</Typography>
					</div>
				</Box>
			</Stack>

			<GeoAutoComplete onLocation={onLocation} />

			<Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: '1em' }}>
				<ToggleButtonGroup value={walkingTime} exclusive onChange={handleWalkingTime}>
					{_.map(c.ISOLINES, (iso) => (
						<ToggleButton key={`iso-${iso}`} value={iso}>
							<HikingIcon />
							{`${iso} min.`}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				{_.isNil(categories) ? (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				) : (
					<Autocomplete
						multiple
						disableCloseOnSelect
						options={categories || []}
						getOptionLabel={(option) => `No ${option.label}`}
						onChange={handleCategoryChange}
						value={selectedCategories}
						fullWidth
						isOptionEqualToValue={(option, value) =>
							!_.isNil(option) && _.get(option, 'id') === _.get(value, 'id')
						}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								<DoNotDisturbIcon
									fontSize="small"
									sx={{ color: '#d7300e', mr: '0.25em', verticalAlign: 'top' }}
								/>
								{option.label}
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label={
									<>
										<DoNotDisturbIcon
											sx={{ color: '#d7300e', mr: '0.25em', verticalAlign: 'top' }}
										/>
										{"Cuisine styles I don't want!"}
									</>
								}
								placeholder="Select a cuisine style to exclude"
							/>
						)}
					/>
				)}
			</Stack>

			<Stack sx={{ mt: '1em' }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
				<LoadingButton
					disabled={_.isNil(currentLocation)}
					loading={lastSearchType === c.SEARCH_TYPE.RANDOM && !_.isNil(inProgress)}
					variant="contained"
					onClick={searchPlaces}
					loadingIndicator={
						<>
							<CircularProgress sx={{ m: '0.25em', mr: '0.5em' }} color="inherit" size={16} />
							{inProgress}
						</>
					}
				>
					{'Feeling Adventurous?'}
				</LoadingButton>
				<LoadingButton
					disabled={_.isNil(currentLocation)}
					loading={lastSearchType === c.SEARCH_TYPE.FROM_FAVS && !_.isNil(inProgress)}
					variant="outlined"
					onClick={pickPlaceFromFavs}
					loadingIndicator={
						<>
							<CircularProgress sx={{ m: '0.25em', mr: '0.5em' }} color="inherit" size={16} />
							{inProgress}
						</>
					}
				>
					{'Pick in my favs!'}
				</LoadingButton>
				<div>
					<IconButton ref={actionMenuAnchorRef} color="primary" onClick={expandActionMenu}>
						<MoreVertIcon />
					</IconButton>
					<Popper
						sx={{ zIndex: 9 }}
						open={actionMenuVisible}
						anchorEl={actionMenuAnchorRef.current}
						role={undefined}
						placement="bottom-start"
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleCloseActionMenu}>
										<MenuList
											sx={{ color: '#d7300e' }}
											autoFocusItem={actionMenuVisible}
											onKeyDown={handleListKeyDown}
										>
											<MenuItem onClick={openMyFavs}>{'My favs'}</MenuItem>
											<MenuItem onClick={openMyBlacklist}>{'My blacklist'}</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Stack>

			<Dialog open={!_.isNil(modalPlaces)} onClose={closeModal}>
				<DialogTitle sx={{ color: '#d7300e' }}>
					{_.isNil(modalPlaces) ? 'Closing...' : `Manage my ${modalPlaces}`}
					<IconButton
						onClick={closeModal}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<>
					<Divider />
					<PlaceList
						places={_.get(userPlaces, [modalPlaces])}
						onRemove={onRemovePlace}
						onClear={onClearPlaces}
					/>
				</>
			</Dialog>

			<Fade in={!_.isNil(result)}>
				<Accordion sx={{ mt: '1em' }}>
					<AccordionSummary>
						<Stack
							sx={{ width: '100%' }}
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={1}
						>
							<Typography variant="h6">
								{_.has(result, c.INFO.NAME)
									? `${Utils.formatInfo(result, c.INFO.NAME)}, ${Utils.formatInfo(
											result,
											c.INFO.ADDRESS
									  )} (${Utils.formatInfo(result, c.INFO.DISTANCE, '?')}m)`
									: ''}
							</Typography>
							<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
								{_.has(userPlaces, [c.LS_FAVS, currentPlaceId]) ? (
									<Typography variant="caption">
										{'(already in your favs)'}
										<StarIcon sx={{ verticalAlign: 'middle', ml: '0.25em' }} />
									</Typography>
								) : (
									<Tooltip title="Yup, in my favs please!">
										<Button variant="outlined" onClick={addToFavorites}>
											<StarOutlineIcon />
										</Button>
									</Tooltip>
								)}
								<Tooltip title="Huh, do not suggest it ever again!">
									<Button variant="outlined" onClick={blacklist}>
										<DoNotDisturbOnOutlinedIcon />
									</Button>
								</Tooltip>
							</Stack>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<Typography variant="button" display="block">
							<div>
								<b>{'type: '}</b>
								{_.map(_.get(result, c.INFO.CATEGORIES), (c, i) => (
									<Chip key={i} sx={{ mr: '0.5em' }} size="small" label={c.name} color={'primary'} />
								))}
							</div>
							<div>
								<b>{'website: '}</b>
								{_.has(result, c.INFO.WEBSITE) && (
									<Link target="_blank" href={_.get(result, c.INFO.WEBSITE)} underline="hover">
										{_.get(result, c.INFO.WEBSITE)}
									</Link>
								)}
								{_.has(result, c.INFO.MENU) && (
									<>
										<DoubleArrowIcon
											size="small"
											fontSize="small"
											sx={{ verticalAlign: 'top', ml: '0.75em', mr: '0.15em' }}
										/>
										<Link target="_blank" href={_.get(result, c.INFO.MENU)} underline="hover">
											<MenuBookIcon
												size="small"
												fontSize="small"
												sx={{ verticalAlign: 'top', mr: '0.25em' }}
											/>
											{'menu'}
										</Link>
									</>
								)}
								<DoubleArrowIcon
									size="small"
									fontSize="small"
									sx={{ verticalAlign: 'top', ml: '0.75em', mr: '0.15em' }}
								/>
								<Link
									target="_blank"
									href={`https://www.google.com/search?q=${encodeURIComponent(
										`${_.get(result, c.INFO.NAME)} ${_.get(result, c.INFO.ADDRESS)}`
									)}`}
									underline="hover"
								>
									<GoogleIcon
										size="small"
										fontSize="small"
										sx={{ verticalAlign: 'top', mr: '0.25em' }}
									/>
									{'Click to Google search it'}
								</Link>
							</div>
							<div>
								<b>{'social media: '}</b>
								{_.has(result, c.INFO.FACEBOOK) && (
									<Link
										target="_blank"
										href={`https://facebook.com/${Utils.formatInfo(result, c.INFO.FACEBOOK, '')}`}
									>
										<FacebookIcon size="small" fontSize="small" sx={{ verticalAlign: 'top' }} />
									</Link>
								)}
								{_.has(result, c.INFO.INSTAGRAM) && (
									<Link
										target="_blank"
										href={`https://instagram.com/${Utils.formatInfo(result, c.INFO.INSTAGRAM, '')}`}
									>
										<InstagramIcon
											size="small"
											fontSize="small"
											sx={{ mr: '0.25em', verticalAlign: 'top' }}
										/>
									</Link>
								)}
								{!_.has(result, c.INFO.FACEBOOK) && !_.has(result, c.INFO.INSTAGRAM) && ` -`}
							</div>

							<div>
								<b>{'phone: '}</b>
								{Utils.formatInfo(result, c.INFO.PHONE)}
							</div>
							<div>
								<b>{'operating hours: '}</b>
								{Utils.formatInfo(result, c.INFO.HOURS)}
								{_.has(result, c.INFO.OPEN_NOW) && _.get(result, c.INFO.OPEN_NOW)
									? ` [OPEN NOW]`
									: ` [CLOSED NOW]`}
							</div>
							<div>
								<b>{'reservation needed: '}</b>
								{_.has(result, c.INFO.BOOKING)
									? Utils.formatInfo(result, c.INFO.BOOKING)
										? 'yes'
										: 'no'
									: 'probably not'}
							</div>
							<div>
								<b>{'tags: '}</b>
								{_.map(_.get(result, c.INFO.TAGS), (t, i) => (
									<Chip key={i} sx={{ mr: '0.5em' }} size="small" label={t} />
								))}
							</div>
							<div>
								<b>{'rating: '}</b>
								{`${Utils.formatInfo(result, c.INFO.RATING)} / 10 (${Utils.formatInfo(
									result,
									c.INFO.N_RATINGS,
									0
								)} reviews)`}
							</div>
							<div>
								<b>{'price: '}</b>
								{_.times(_.get(result, c.INFO.PRICE), (i) => (
									<EuroIcon key={i} fontSize="small" sx={{ verticalAlign: 'top' }} />
								))}
							</div>
							<div>
								<b>{'amenities:'}</b>
								{_.get(result, c.INFO.ACCESSIBILITY) && (
									<Tooltip title="Wheelchair Accessible">
										<AccessibleIcon fontSize="small" sx={{ ml: '0.25em', verticalAlign: 'top' }} />
									</Tooltip>
								)}
								{_.get(result, c.INFO.OUTDOOR) && (
									<Tooltip title="Outdoor Seating">
										<DeckIcon fontSize="small" sx={{ ml: '0.25em', verticalAlign: 'top' }} />
									</Tooltip>
								)}
								{_.get(result, c.INFO.TAKEAWAY) && (
									<Tooltip title="Take Away">
										<FoodBankIcon fontSize="small" sx={{ ml: '0.25em', verticalAlign: 'top' }} />
									</Tooltip>
								)}
								{_.get(result, c.INFO.DELIVERY) && (
									<Tooltip title="Delivery">
										<DeliveryDiningIcon
											fontSize="small"
											sx={{ ml: '0.25em', verticalAlign: 'top' }}
										/>
									</Tooltip>
								)}
								{!_.has(result, c.INFO.ACCESSIBILITY) &&
									!_.has(result, c.INFO.OUTDOOR) &&
									!_.has(result, c.INFO.DELIVERY) &&
									!_.has(result, c.INFO.TAKEAWAY) &&
									` -`}
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Fade>

			<Box
				sx={{
					m: '1em',
					width: 'calc(100% - 2em)',
					height: 'calc(100vh / 2)',
					align: 'center'
				}}
			>
				<div ref={mapContainer} style={{ width: '100%', height: '100%' }} className="map" />
			</Box>
		</Container>
	);
}

export default App;
