import { useState, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';

import * as c from '../Utils/Constants';
import * as Utils from '../Utils/Utils';

const PlaceList = ({ places, onRemove, onClear }) => {
	const [checked, setChecked] = useState({});

	const handleToggle = (value) => () => {
		const id = _.get(value, [c.PLACE_ID_KEY]);
		if (!_.isNil(id)) {
			setChecked((old) => {
				return {
					...old,
					[id]: !_.get(old, [id], false)
				};
			});
		}
	};

	const selectedIds = useMemo(() => {
		return _.keys(_.pickBy(checked));
	}, [checked]);

	const onRemoveSelection = () => {
		if (_.isFunction(onRemove)) {
			onRemove(selectedIds);
		}
	};

	const onClearAll = () => {
		if (_.isFunction(onClear)) {
			onClear();
		}
	};

	return (
		<Box>
			{_.isEmpty(places) ? (
				<Typography sx={{ m: '2em' }} variant={'body1'}>
					{'There is no recorded places here'}
				</Typography>
			) : (
				<>
					<Stack sx={{ m: '1em' }} direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
						<Button
							variant="outlined"
							startIcon={<ClearIcon />}
							onClick={onRemoveSelection}
							disabled={_.isEmpty(selectedIds)}
						>
							{'Remove selected'}
						</Button>
						<Button variant="contained" startIcon={<DeleteSweepIcon />} onClick={onClearAll}>
							{'Clear all'}
						</Button>
					</Stack>
					<List>
						{_.map(places, (p, i) => (
							<ListItem key={i}>
								<ListItemButton role={undefined} onClick={handleToggle(p)} dense>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={_.get(checked, [_.get(p, [c.PLACE_ID_KEY])], false)}
											tabIndex={-1}
											disableRipple
										/>
									</ListItemIcon>
									<ListItemText
										primary={`${Utils.formatInfo(p, c.INFO.NAME)} (${_.join(
											_.map(_.get(p, c.INFO.CATEGORIES), (c) => c.name),
											', '
										)})`}
										secondary={Utils.formatInfo(p, c.INFO.ADDRESS)}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</>
			)}
		</Box>
	);
};

export default PlaceList;
